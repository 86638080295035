import * as Sentry from '@sentry/browser';
import { clientApi } from './clientApi';
import { serverSideApi } from './serverSideApi';

export async function getOrders() {
  try {
    return await clientApi('/ecommerce/orders/physical/profile', 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getPhysicalOrder(orderNumber) {
  try {
    return await clientApi(`/ecommerce/orders/physical/order/${orderNumber}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getOrderSubscriptionInfo(req, res) {
  try {
    return await serverSideApi(req, res, '/ecommerce/orders/subscription/info', 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getSubscriptionCheckoutSessionInfo(session_id) {
  try {
    return await clientApi(`/ecommerce/orders/subscription/checkout/info/${session_id}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getPhysicialOrderCheckoutSessionInfo(session_id) {
  try {
    return await clientApi(`/ecommerce/orders/physical/checkout/info/${session_id}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function upgradeLicense(price_id) {
  try {
    return await clientApi(`/ecommerce/orders/subscription/price/update/${price_id}`, 'POST');
  } catch (error) {
    Sentry.captureException(error);
  }
}
